import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BASE_URL } from "../config";
import axios from 'axios';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const handleLoginSubmit = (event) => {
        setError("");
        setLoading(true);
        axios({
            url: `${BASE_URL}/auth/signin`, //your url
            method: 'POST',
            data: {
                "email": email,
                "password": password
            }
        }).then((res) => {
            setLoading(false);
            let data = res?.data?.data;
            localStorage.setItem('walkthrough', JSON.stringify({ walkThroughPending: data?.walkThroughPending, walkThroughSteps: data?.walkThroughSteps }))
            localStorage.setItem('auth-token', data?.accessToken);
            localStorage.setItem('company', data?.company ? JSON.stringify(data?.company) : '');
            localStorage.setItem('user', JSON.stringify({ price: data?.price, userCount: data.userCount, userRange: data?.userRange, expectedCftUser: data?.expectedCftUser }));
            localStorage.setItem('roles', data?.roles);
            localStorage.setItem('user-name', (data?.firstName || data?.companyName) + ' ' + (data?.lastName || ''));
            localStorage.setItem('email', data?.email);
            localStorage.setItem('subscribedStatus', data?.subscribedStatus || false);
            localStorage.setItem('planType', data?.planType || '');
            localStorage.setItem('coupon', data?.coupon || '');
            localStorage.setItem('status', data?.status || '');
            setTimeout(() => {
                if (data?.walkThroughPending && data?.walkThroughSteps === '0') {
                    if (data?.roles === 'user' && data?.company?.email) {
                        navigate('/device');
                    } else {
                        navigate('/membership');
                    }
                } else if (data?.walkThroughPending && data?.walkThroughSteps === '1') {
                    navigate('/device');
                } else {
                    navigate('/dashboard');
                }
            }, 200);
        }).catch((err) => {
            if (err?.response?.data?.message) {
                setError(err?.response?.data?.message);
                setTimeout(() => {
                    setError("");
                }, 3000);
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        let isLogin = localStorage.getItem('auth-token');
        if (!isLogin && !(location.pathname === '/' || location.pathname === '/sign-up')) {
            navigate('/');
        } else if (isLogin && location.pathname === '/') {
            navigate('/dashboard');
        }
    }, [location]);

    return (
        <>
            <div className="bg-theme-blue h-screen">
                <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8" style={{ height: '550px' }}>
                    <div className="w-full max-w-md my-8">
                        <div className="flex justify-center">
                            <img style={{ height: "160px" }} src={require('../assets/logo-lg.png')} />
                        </div>
                        <form style={{ zIndex: '80000' }} className="" action="#" method="POST">
                            <input type="hidden" name="remember" value="true" />
                            <div className="-space-y-px rounded-md shadow-sm">
                                <div className="my-2">
                                    <label htmlFor="email-address" className="sr-only">Email address</label>
                                    <input
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        id="email-address"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        required
                                        className="bg-theme-blue autofill:bg-slate-800 relative block w-full appearance-none border border-[#2d3d5c] px-3 py-2 text-gray-300 placeholder-gray-600 focus:z-10 focus:border-gray-400 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        placeholder="Email address"
                                    />
                                </div>
                                <div className="my-2">
                                    <label htmlFor="password" className="sr-only">Password</label>
                                    <input
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        required
                                        className="bg-theme-blue autofill:bg-slate-800 relative block w-full appearance-none border border-[#2d3d5c] px-3 py-2 text-gray-300 placeholder-gray-600 focus:z-10 focus:border-gray-400 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        placeholder="Password"
                                    />
                                </div>
                            </div>
                            <div className="text-red-500 pt-2 text-center h-8">{error}</div>
                            <div className="flex justify-center">
                                <button onClick={() => handleLoginSubmit()} type="button" className="group relative flex w-40 justify-center rounded-md border border-transparent bg-[#4285F4] py-2 px-4 text-sm font-medium text-white hover:bg-[#4285F4] focus:outline-none">
                                    {loading ? (<div className="lds-facebook"><div></div><div></div><div></div><div></div><div></div></div>) : "Login"}
                                </button>
                            </div>
                            <div className="text-sm text-center my-4">
                                <a href="" onClick={() => navigate('/forgot/password')} className="font-medium text-gray-400 hover:text-gray-200">Forgot your password?</a>
                            </div>
                            <div className="text-sm text-gray-300 text-center my-4">
                                Don't have an account ?
                                <a href="" onClick={() => navigate('/sign-up')} className="font-medium text-blue-400 hover:underline mx-2">sign up</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Login;