import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from "../config";
import moment from 'moment';
const SignUp = () => {
    const [userForm, setUserForm] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        dob: "",
        coupon: "",
        roles: "user"
    });
    const [loading, setLoading] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [errorMessage, setErrorMessage] = useState(false);
    const [showError, setShowError] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState(true);

    const handleLoginSubmit = (event) => {

        if (!userForm?.firstName || !userForm?.lastName || !userForm?.email || !userForm?.password || !userForm?.confirmPassword|| !userForm?.dob) {
            setShowError(true);

            setTimeout(function(){
                setErrorMessage('');
                setShowError(false);
            }, 4000);

            return;
        }

        if (userForm.password !== userForm.confirmPassword) {
            setPasswordMatch(false);
            return;
        } else {
            setPasswordMatch(true);
        }

        setError("");
        setLoading(true);
        axios({
            url: `${BASE_URL}/auth/signup`, //your url
            method: 'POST',
            data: userForm
        }).then((res) => {
            setLoading(false);
            setTimeout(() => {
                navigate(`/sign-up/success/${userForm?.firstName}`);
            }, 200);
        }).catch((err) => {
            if (err?.response?.data?.message) {
                setError(err?.response?.data?.message);
                setTimeout(() => {
                    setError("");
                }, 3000);
            }
            setLoading(false);
        });
    };

    const handleConfirmPasswordChange = (e) => {
        setUserForm({ ...userForm, confirmPassword: e.target.value });
        if (e.target.value !== userForm.password) {
            setPasswordMatch(false);
        } else {
            setPasswordMatch(true);
        }
    };

    const validateEmails = (e) => {
        setUserForm({ ...userForm, email: e.target.value });

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(e.target.value.trim())) {
            
            setErrorMessage('Invalid email address.');

            setTimeout(function(){
                setErrorMessage('');
                setShowError(false);
            }, 4000);

            return false;
        }
        return true;
    };

    useEffect(() => {
        let isLogin = localStorage.getItem('auth-token');
        console.log(location, '======', isLogin);
        if (!isLogin && !(location.pathname === '/' || location.pathname === '/sign-up')) {
            navigate('/');
        } else if (isLogin && location.pathname === '/') {
            navigate('/dashboard');
        }
    }, [location]);

    return (
        <>
            <div className="bg-theme-blue h-screen">
                <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 pt-28" style={{ height: '550px' }}>
                    <div className="w-full max-w-md my-8">
                        <div className="flex justify-center">
                            <img style={{ height: "160px" }} src={require('../assets/logo-lg.png')} />
                        </div>
                        <form style={{ zIndex: '80000' }} className="" action="#" method="POST">
                            <input type="hidden" name="remember" value="true" />
                            <div className="rounded-md shadow-sm">
                                <div className="my-2">
                                    <label htmlFor="firstName" className="font-normal mb-4 text-gray-300 dark:text-gray-400">First Name *</label>
                                    <input
                                        value={userForm?.firstName}
                                        onChange={(e) => setUserForm({ ...userForm, firstName: e.target.value })}
                                        id="firstName"
                                        name="firstName"
                                        type="text"
                                        className="bg-theme-blue autofill:bg-slate-800 relative block w-full appearance-none border border-[#2d3d5c] px-3 py-2 text-gray-300 placeholder-gray-600 focus:z-10 focus:border-gray-400 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        placeholder="First Name"
                                    />
                                </div>
                                <div className="my-2">
                                    <label htmlFor="lastName" className="font-normal mb-4 text-gray-300 dark:text-gray-400">Last Name *</label>
                                    <input
                                        value={userForm?.lastName}
                                        onChange={(e) => setUserForm({ ...userForm, lastName: e.target.value })}
                                        id="lastName"
                                        name="lastName"
                                        type="text"
                                        className="bg-theme-blue autofill:bg-slate-800 relative block w-full appearance-none border border-[#2d3d5c] px-3 py-2 text-gray-300 placeholder-gray-600 focus:z-10 focus:border-gray-400 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        placeholder="Last Name"
                                    />
                                </div>
                                <div className="my-2">
                                    <label htmlFor="email-address" className="font-normal mb-4 text-gray-300 dark:text-gray-400">Email address *</label>
                                    <input
                                        value={userForm?.email}
                                        onChange={validateEmails}
                                        id="email-address"
                                        type="email"
                                        required
                                        className="bg-theme-blue autofill:bg-slate-800 relative block w-full appearance-none border border-[#2d3d5c] px-3 py-2 text-gray-300 placeholder-gray-600 focus:z-10 focus:border-gray-400 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        placeholder="Email address"
                                    />
                                </div>
                                <div className="my-2">
                                    <label htmlFor="password" className="font-normal mb-4 text-gray-300 dark:text-gray-400">Password *</label>
                                    <input
                                        value={userForm?.password}
                                        onChange={(e) => setUserForm({ ...userForm, password: e.target.value })}
                                        id="password"
                                        type="password"
                                        required
                                        className="bg-theme-blue autofill:bg-slate-800 relative block w-full appearance-none border border-[#2d3d5c] px-3 py-2 text-gray-300 placeholder-gray-600 focus:z-10 focus:border-gray-400 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        placeholder="Password"
                                    />
                                </div>
                                <div className="my-2">
                                    <label htmlFor="password" className="font-normal mb-4 text-gray-300 dark:text-gray-400">Confirm Password *</label>
                                    <input
                                        value={userForm?.confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                        id="confirmPassword"
                                        type="password"
                                        required
                                        className="bg-theme-blue autofill:bg-slate-800 relative block w-full appearance-none border border-[#2d3d5c] px-3 py-2 text-gray-300 placeholder-gray-600 focus:z-10 focus:border-gray-400 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        placeholder="Confirm Password"
                                    />
                                </div>
                                <div className="my-2">
                                    <label htmlFor="password" className="font-normal mb-4 text-gray-300 dark:text-gray-400">DOB *</label>
                                    <input
                                        value={userForm?.dob}
                                        onChange={(e) => setUserForm({ ...userForm, dob: e.target.value })}
                                        id="password"
                                        name="password"
                                        type="date"
                                        autoComplete="current-password"
                                        max={moment().subtract(10, 'years').format('YYYY') + '-12-31'}
                                        min={moment().subtract(100, 'years').format('YYYY') + '-12-31'}
                                        required
                                        className="bg-theme-blue autofill:bg-slate-800 relative block w-full appearance-none border border-[#2d3d5c] px-3 py-2 text-gray-300 placeholder-gray-600 focus:z-10 focus:border-gray-400 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        placeholder="DOB"
                                    />
                                </div>
                                <div className="my-2 mb-5">
                                    <label htmlFor="firstName" className="font-normal mb-4 text-gray-300 dark:text-gray-400">Coupon Code</label>
                                    <input
                                        value={userForm?.coupon}
                                        onChange={(e) => setUserForm({ ...userForm, coupon: e.target.value })}
                                        id="coupon"
                                        name="coupon"
                                        type="text"
                                        className="bg-theme-blue autofill:bg-slate-800 relative block w-full appearance-none border border-[#2d3d5c] px-3 py-2 text-gray-300 placeholder-gray-600 focus:z-10 focus:border-gray-400 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        placeholder="Coupon Code"
                                    />
                                    <span style={{color: 'rgb(109 167 110)', fontSize: '13px'}}>Apply coupon code if you have any.</span>

                                    {showError && <p className="text-red-500 my-2">Fields with star (*) are required.</p>}
                                    {!passwordMatch && <p className="text-red-500 my-2">Passwords do not match</p>}
                                    <p className="text-red-500 my-2">{errorMessage}</p>
                                </div>
                            </div>
                            <span>
                                <span className="text-gray-400 pr-2">By signing up you declare you have read and agreed with our</span>
                                <a href="https://beta.contingentfitness.com/terms-and-conditions/" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
                                <span className="text-gray-400 pl-2">and</span>
                                <a href="https://beta.contingentfitness.com/privacy-policy/" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>.
                            </span>
                            <div className="text-red-500 pt-2 text-center h-8">{error}</div>
                            <div className="flex justify-center">
                                <button onClick={() => handleLoginSubmit()} type="button" className="group relative flex w-40 justify-center rounded-md border border-transparent bg-[#4285F4] py-2 px-4 text-sm font-medium text-white hover:bg-[#4285F4] focus:outline-none">
                                    {loading ? (<div className="lds-facebook"><div></div><div></div><div></div><div></div><div></div></div>) : "Sign Up"}
                                </button>
                            </div>
                            <div className="text-sm text-center my-4">
                                <span className="text-gray-400">Already have an account ?</span>
                                <a href="" onClick={() => navigate('/')} className="font-medium text-blue-700 hover:underline mx-2">log in</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SignUp;
