const url1 = {
    BASE_URL: "http://localhost:5000",
    CLIENT_BASE_URL: "http://localhost:3000",
    FITBIT_CLIENT_ID: "23RDRZ",
    STRIPE_PUBLISHABLE_KEY: "pk_test_1AZtjtpnxCLoSvGb1DP48yP2"
};

const url = {
    BASE_URL: "https://api.contingentfitness.com",
    CLIENT_BASE_URL: "https://app.contingentfitness.com",
    FITBIT_CLIENT_ID: "23RDRZ",
    STRIPE_PUBLISHABLE_KEY: "pk_live_P1FhfWoh7B5aEP7ycjFGbrBE"
};

module.exports = url;
