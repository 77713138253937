import React, { useRef, useState, useEffect } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts';
import * as echarts from 'echarts';

const RenderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, centerText, name } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill='white'>
                <tspan x={cx} dy="-0.2em">{centerText[0]}</tspan>
                <tspan x={cx} dy="1.2em">{centerText[1]}</tspan>
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="white" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="white">{`${name}`}</text>
            {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text> */}
        </g>
    );
};

const PieChartCustom = ({ data, centerText }) => {
    const chartRef = useRef(null);


    useEffect(() => {
        setTimeout(() => {
            const myChart = echarts.init(chartRef.current);
            // Configure your chart options here
            const options = {
                tooltip: {
                    trigger: 'item',
                    formatter: '{c} minutes'
                },
                legend: {
                    bottom: '4%',
                    textStyle: {
                        color: 'white', // Legend label text color
                    },
                },
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: true,
                            position: 'center',
                            text: "hello",
                            fontSize: 16,
                            formatter: (params) => {
                                return centerText;
                            },
                            textStyle: {
                                color: 'white',
                            },
                        },
                        labelLine: {
                            show: false
                        },
                        data: data
                    }
                ]
            };

            myChart.setOption(options);
            // Clean up the chart when the component unmounts
            return () => {
                myChart.dispose();
            };
        }, 100);
    }, [centerText]);

    return (
        <div ref={chartRef} centerText={centerText} style={{ width: '300px', height: '300px' }} />
    );
}

export default PieChartCustom;