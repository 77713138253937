import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BASE_URL } from "../../config";
import axios from 'axios';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [loading, setLoading] = useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const handleLoginSubmit = (event) => {
        setError("");
        setLoading(true);
        axios({
            url: `${BASE_URL}/auth/reset/password/link`, //your url
            method: 'POST',
            data: {
                "email": email
            }
        }).then((res) => {
            setSuccess(true);
            setLoading(false);
            setName(res?.data?.data?.name);
        }).catch((err) => {
            if (err?.response?.data?.message) {
                setError(err?.response?.data?.message);
                setTimeout(() => {
                    setError("");
                }, 3000);
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        let isLogin = localStorage.getItem('auth-token');
        if (isLogin && location.pathname === '/') {
            navigate('/dashboard');
        }
    }, [location]);

    return (
        <>
            <div className="bg-theme-blue h-screen">
                <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8" style={{ height: '550px' }}>
                    {success ? (
                        <div className="">
                            <div className="md:w-[100%] bg-theme-blue-secondary p-10 pb-20">
                                <div className="flex justify-center text-green-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-10 h-10">
                                        <path stroke-linecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                                    </svg>
                                </div>
                                <div className="text-gray-300">
                                    <div className="text-md m-10">
                                        <div>Hello {name}, </div>
                                        Your have recieved reset password link on your email.
                                        <br />
                                        Please check your email.
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <button onClick={() => navigate('/')} type="button" className="group relative flex w-40 justify-center rounded-md border border-transparent bg-[#4285F4] py-2 px-4 text-sm font-medium text-white hover:bg-[#4285F4] focus:outline-none">
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="w-full max-w-md my-8">
                            <div className="flex justify-center">
                                <img style={{ height: "160px" }} src={require('../../assets/logo-lg.png')} />
                            </div>
                            <p className="text-center mb-10 font-bold text-gray-400 truncate dark:text-white">
                                FORGOT PASSWORD
                            </p>
                            <form style={{ zIndex: '80000' }} className="" action="#" method="POST">
                                <input type="hidden" name="remember" value="true" />
                                <div className="-space-y-px rounded-md shadow-sm">
                                    <div className="mt-2">
                                        <label htmlFor="email-address" className="sr-only">Email address</label>
                                        <input
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            id="email-address"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            required
                                            className="bg-theme-blue autofill:bg-slate-800 relative block w-full appearance-none border border-[#2d3d5c] px-3 py-2 text-gray-300 placeholder-gray-600 focus:z-10 focus:border-gray-400 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            placeholder="Enter your email address"
                                        />
                                    </div>
                                </div>
                                <div className="text-red-500 pt-2 pb-8 text-center h-6">{error}</div>
                                <div className="flex justify-center">
                                    <button onClick={() => handleLoginSubmit()} type="button" className="group relative flex w-40 justify-center rounded-md border border-transparent bg-[#4285F4] py-2 px-4 text-sm font-medium text-white hover:bg-[#4285F4] focus:outline-none">
                                        {loading ? (<div className="lds-facebook"><div></div><div></div><div></div><div></div><div></div></div>) : "Send Reset Link"}
                                    </button>
                                </div>
                                <div className="text-sm text-center my-4">
                                    <a href="" onClick={() => navigate('/')} className="font-medium text-gray-400 hover:text-gray-200">Back To Login</a>
                                </div>
                                <div className="text-sm text-gray-300 text-center my-4">
                                    Don't have an account ?
                                    <a href="" onClick={() => navigate('/sign-up')} className="font-medium text-blue-400 hover:underline mx-2">sign up</a>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
export default ForgotPassword;